.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonAndLogo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.logoContainer {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  overflow: hidden;
}

.logoContainer img {
  width: 100%;
}

.logoContainer.xsLogoContainer {
  width: 33px;
  height: 33px;
}

.phone {
  width: 80%;
  margin-left: 3%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.phoneNum {
  width: 31%;
}
.email {
  width: 31%;
}
.location {
  width: 38%;
}
