@font-face {
  font-family: "TisaSansPro-Regular";
  src: local("TisaSansPro-Regular"),
    url(assets/fonts/TisaSansPro-Regular.woff) format("woff");
}

@font-face {
  font-family: "Siemreap";
  src: local("KHMEROSSIEMREAP"),
    url(assets/fonts/KHMEROSSIEMREAP.TTF) format("tff");
}

.form {
  text-align: center;
}

.contact-form {
  display: inline-block;
  justify-content: center;
}
.form-validation {
  background-color: rgba(219, 201, 201, 0.753);
  color: rgb(17, 17, 151);
  justify-content: center;
}
input:focus {
  box-shadow: none;
  outline: none;
}
.form-section {
  margin: 0 auto;
}
button {
  border-radius: 5px;
  justify-content: center;
}
.inputfield {
  background-color: whitesmoke;
  border: 1px solid black;
  border-width: 2px;
  margin: 10px;
  padding: 5px;
}
.join-img {
  margin: 0 auto;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease-in-out !important;
  left: 0px !important;
  bottom: 0px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.swiper-pagination-bullet-active {
  background-color: #004667 !important;
}
