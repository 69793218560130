.topBarContainer {
  position: absolute;
  top: 20px;
  display: flex;
  width: 80%;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
}

.logoContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.144), 0 3px 10px 0 rgba(0, 0, 0, 0.13);
  overflow: hidden;
  align-content: center;
  justify-content: center;
}

.logoContainer img {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container.smContainer svg {
  width: 20px;
  height: 20px;
}
.container.smContainer div {
  font-size: 0.7rem;
}
.container.container.smContainer {
  justify-content: flex-end;
}

.container.xsContainer svg {
  width: 20px;
  height: 20px;
}
.container.xsContainer div {
  font-size: 0.6rem;
}
.container.xsContainer {
  justify-content: flex-end;
}

.iconContainer {
  display: flex;
  align-self: center;
}

.textContainer {
  width: 100%;
  color: white;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
}
.textContainer div {
  width: 100%;
  text-shadow: 1px 1px 2px black;
}
