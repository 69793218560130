.navBar {
  width: 80%;
  transition: width 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  z-index: 10;
}

.navBar.stickyNavBar {
  position: fixed;
  top: 0;
  width: 100%;
}

.md {
  color: rgb(218, 218, 218);
}
