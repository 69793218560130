body, html {
  margin: 0;
  font-family: "TisaSansPro-Regular", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}

#mainNavBar a:hover {
  text-decoration: none;
}

#root {
  overflow-x: hidden;
  font-family: "TisaSansPro-Regular", "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
